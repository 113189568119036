import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { INPUT_SIZES } from 'helpers/forms';

import Info from 'components/ui/Atoms/Icons/Info';
import Tooltip from 'components/Tooltip';
import Typography from 'components/ui/Atoms/Typography';
import styles from './InputLabelStyles';

const useStyles = makeStyles({ name: 'InputLabel' })(styles);

const InputLabel = (props) => {
  const {
    className,
    htmlFor,
    children,
    disabled,
    size,
    component,
    description,
    isRequired,
    ...otherProps
  } = props;
  const { classes, cx } = useStyles();

  return (
    <Typography
      variant={size === 'large' ? 'paragraph' : 'caption2'}
      component={component}
      htmlFor={component === 'label' ? htmlFor : null}
      className={cx(
        classes.root,
        classes[size],
        { [classes.disabled]: disabled },
        className,
      )}
      {...otherProps}
    >
      <span className={classes.text}>
        {children}
        {isRequired && <span className={classes.requiredAsterisk}>*</span>}
      </span>
      {description && (
        <Tooltip title={description || ''}>
          <Info className={classes.icon} />
        </Tooltip>
      )}
    </Typography>
  );
};

InputLabel.defaultProps = {
  className: undefined,
  htmlFor: undefined,
  isRequired: false,
  disabled: false,
  component: 'label',
  size: 'medium',
  description: undefined,
};

InputLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  component: PropTypes.node,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  htmlFor: PropTypes.string,
  isRequired: PropTypes.bool,
  size: PropTypes.oneOf(INPUT_SIZES),
};

export default InputLabel;
